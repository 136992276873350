<template>
    <div class="section">
        <div class="columns is-centered" >
            <div class="column box is-12-mobile is-9-tablet is-8-desktop is-8-widescreen">

                <div class="section">
                    <div id="liqpay_checkout"></div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {CONFIRM_INVOICE_PAYMENT, GET_INVOICE} from "@/store/modules/invoices/action-types";
import {mapActions} from "vuex";

export default {
    name: "Checkout",
    title(){ return this.$t('Checkout');},
    data(){
        return {
            invoice: null,
        };
    },
    methods: {
        ...mapActions('invoices', [
            GET_INVOICE,
            CONFIRM_INVOICE_PAYMENT,
        ]),
        handleCallback(data){
            if ('success' !== data.status) return;
            this[CONFIRM_INVOICE_PAYMENT]({
                order_id: this.invoice.id,
                signature: data.signature,
                data: data.data,
                response: data,
            });
        },
        mountLiqPayWidget(){
            window.LiqPayCheckoutCallback = () => {
                window.LiqPayCheckout.init({
                    data: this.invoice.data,
                    signature: this.invoice.signature,
                    embedTo: "#liqpay_checkout",
                    mode: "embed" // embed || popup,
                }).on("liqpay.callback", this.handleCallback);
            };
        },
        setRequiredInvoice(){
            return new Promise((resolve, reject) => {
                if (this.$route.params.invoice){
                    this.invoice = this.$route.params.invoice;
                    resolve(this.invoice);
                } else if (this.$route.params.invoice_id){
                    this[GET_INVOICE]()
                        .then(res => {
                            this.invoice = res.data;
                            resolve(this.invoice);
                        });
                } else {
                    reject();
                }
            });

        }
    },
    mounted() {
        let liqPayScript = document.createElement('script');
        liqPayScript.setAttribute('src', '//static.liqpay.ua/libjs/checkout.js');
        liqPayScript.async = true;
        document.head.appendChild(liqPayScript);
        this.setRequiredInvoice()
            .then(() => {
                this.mountLiqPayWidget();
            });
    },
};
</script>

<style scoped>

</style>